import React from 'react'

const Logo = ({size}) => {
  return ( <div style={{height: size, width: size}}>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 300 300" enableBackground="new 0 0 300 300">
    <g>
     <path fill="#CA3332" d="M298.2,171.8L298.2,171.8c-9.1-9.1-12-22.4-7.7-34.9c1.3-3.6,2-7.4,2.2-11.3c0.6-11.5-3.4-22.4-11.3-30.8
     c-8-8.5-19.4-13.4-31.2-13.4h-2.3c-19.3-30.4-53.2-50.7-91.8-50.7c-59.9,0-108.6,48.7-108.6,108.6c0,26.1,9.2,50,24.6,68.8
     c-19.4-5.3-33.3-23.3-33.3-45.2v-49.4c0-17.7-14.4-32.1-32.1-32.1H4.8c-2.6,0-4.8,2.1-4.8,4.8s2.1,4.8,4.8,4.8h1.8
     c12.4,0,22.5,10.1,22.5,22.5V163c0,31.1,23.4,55.6,53.4,56.4c19.4,17.8,45.2,28.7,73.5,28.7c59.9,0,108.6-48.7,108.6-108.6
     c0-17.3-4.1-33.6-11.3-48.2c8,0.8,15.6,4.4,21.1,10.2c6.1,6.4,9.2,14.9,8.7,23.7c-0.2,3-0.7,5.9-1.7,8.7c-5.5,16-1.7,33.1,9.9,44.7
     l0.1,0.1c0.9,0.9,2.2,1.4,3.4,1.4c1.2,0,2.4-0.5,3.4-1.4C300.1,176.7,300.1,173.7,298.2,171.8z M156,238.5c-54.6,0-99-44.4-99-99
     c0-54.6,44.4-99,99-99c54.6,0,99,44.4,99,99C255,194,210.6,238.5,156,238.5z"/>
     <g>
    
       <path fill="#CA3332" stroke="#CA3332" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
       M153.6,139.4"/>
     </g>
     <g>
     <circle fill="#CA3332" cx="156" cy="12.6" r="12.6"/>
     </g>
    </g>
    <g>
     <path fill="#CA3332" d="M9.1,271H0.2v-4.2H23v4.2h-8.9v22.7h-5V271z"/>
     <path fill="#CA3332" d="M50.8,289.4v4.2H30.6v-26.9h19.6v4.2H35.6v7h13v4.1h-13v7.4H50.8z"/>
     <path fill="#CA3332" d="M69.4,282.4l-4.3,4.4v6.9h-5v-26.9h5v13.8l13.3-13.8H84l-11.3,12l12,14.9h-5.8L69.4,282.4z"/>
     <path fill="#CA3332" d="M96.2,292.2c-2.2-1.2-3.9-2.8-5.2-5c-1.3-2.1-1.9-4.5-1.9-7.1c0-2.6,0.6-5,1.9-7.1c1.3-2.1,3-3.8,5.2-5
     c2.2-1.2,4.7-1.8,7.4-1.8c2.7,0,5.2,0.6,7.4,1.8c2.2,1.2,3.9,2.8,5.2,4.9c1.3,2.1,1.9,4.5,1.9,7.1c0,2.6-0.6,5-1.9,7.1
     c-1.3,2.1-3,3.7-5.2,4.9c-2.2,1.2-4.7,1.8-7.4,1.8C100.9,294,98.4,293.4,96.2,292.2z M108.4,288.4c1.4-0.8,2.6-1.9,3.4-3.4
     c0.8-1.4,1.2-3.1,1.2-4.9c0-1.8-0.4-3.4-1.2-4.9c-0.8-1.4-1.9-2.6-3.4-3.4c-1.4-0.8-3-1.2-4.8-1.2c-1.8,0-3.4,0.4-4.8,1.2
     c-1.4,0.8-2.6,1.9-3.4,3.4c-0.8,1.4-1.2,3.1-1.2,4.9c0,1.8,0.4,3.4,1.2,4.9c0.8,1.4,1.9,2.6,3.4,3.4c1.4,0.8,3,1.2,4.8,1.2
     C105.4,289.6,107,289.2,108.4,288.4z"/>
     <path fill="#CA3332" d="M166.3,293.6l0-17.8l-8.8,14.7h-2.2l-8.8-14.5v17.6h-4.8v-26.9h4.1l10.7,17.8l10.5-17.8h4.1l0,26.9H166.3z"
     />
     <path fill="#CA3332" d="M202.3,289.4v4.2h-20.2v-26.9h19.6v4.2h-14.6v7h13v4.1h-13v7.4H202.3z"/>
     <path fill="#CA3332" d="M229.3,293.6l-5.5-7.9c-0.2,0-0.6,0-1,0h-6.1v7.8h-5v-26.9h11.1c2.3,0,4.4,0.4,6.1,1.2
     c1.7,0.8,3.1,1.9,4,3.3c0.9,1.4,1.4,3.1,1.4,5.1c0,2-0.5,3.8-1.5,5.2s-2.4,2.5-4.2,3.3l6.2,8.8H229.3z M227.5,272.3
     c-1.1-0.9-2.8-1.4-5-1.4h-5.8v10.7h5.8c2.2,0,3.8-0.5,5-1.4c1.1-0.9,1.7-2.3,1.7-4S228.6,273.3,227.5,272.3z"/>
     <path fill="#CA3332" d="M261,287.4h-13.4l-2.6,6.2h-5.1l12.1-26.9h4.9l12.1,26.9h-5.2L261,287.4z M259.4,283.5l-5.1-11.7l-5,11.7
     H259.4z"/>
     <path fill="#CA3332" d="M300.1,266.7v26.9h-5v-11.5h-13.9v11.5h-5v-26.9h5v11.1h13.9v-11.1H300.1z"/>
    </g>
    </svg>
  </div> );
}
 
export default Logo;